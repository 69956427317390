var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cardLayout" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.cardLoading,
            expression: "cardLoading",
          },
        ],
        staticClass: "cardListBig",
      },
      [
        _c(
          "div",
          { staticClass: "cardList" },
          _vm._l(_vm.cardData, function (item, index) {
            return _c("div", { key: index, staticClass: "cardBox" }, [
              _vm.cardProps.imgUrl == "courseCover" &&
              item[_vm.cardProps.imgUrl] == ""
                ? _c("img", {
                    staticClass: "cardImg",
                    attrs: {
                      src: require("@/assets/training/trainingDefaultPicture.png"),
                    },
                  })
                : _c("img", {
                    staticClass: "cardImg",
                    attrs: { src: item[_vm.cardProps.imgUrl] },
                  }),
              _c(
                "div",
                {
                  staticClass: "cardTitle",
                  style: { color: _vm.themeColor },
                  on: {
                    click: function ($event) {
                      return _vm.cardView(item)
                    },
                  },
                },
                [_vm._v(_vm._s(item[_vm.cardProps.title]))]
              ),
              _c("div", { staticClass: "cardTime" }, [
                _vm._v(_vm._s(item[_vm.cardProps.time])),
              ]),
              _c(
                "div",
                { staticClass: "cardBtn" },
                [_vm._t("default", null, { row: item })],
                2
              ),
            ])
          }),
          0
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "cardPagination" },
      [
        _c("el-pagination", {
          attrs: {
            background: "",
            "current-page": _vm.page.currentPage,
            "page-sizes": _vm.page.pageSizes,
            "page-size": _vm.page.pageSize,
            layout: "total, sizes, prev, pager, next, jumper",
            total: _vm.page.total,
          },
          on: {
            "size-change": _vm.sizeChange,
            "current-change": _vm.currentChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }